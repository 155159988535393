import {Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../i18n/language.service';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {AsyncPipe, NgClass, NgForOf} from '@angular/common';

@Component({
    selector: 'app-select-language',
    templateUrl: './select-language.component.html',
    styleUrls: ['./select-language.component.scss'],
    standalone: true,
    imports: [
        MatTooltip,
        TranslateModule,
        MatMenuTrigger,
        AsyncPipe,
        NgClass,
        MatMenu,
        NgForOf,
        MatMenuItem,
    ],
})
export class SelectLanguageComponent implements OnInit {
    public currentLang$: Observable<string>;
    public menuOpened: boolean = false;

    constructor(public langService: LanguageService) {}

    public ngOnInit(): void {
        this.currentLang$ = this.langService.languageChange$;
    }
}

import {Component} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {SidebarService} from '../../shared/services/sidebar.service';
import {Store} from '@ngxs/store';
import {AppState} from '../../store/app/app.state';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {AuthenticationService} from '../auth/authentication.service';
import {ConfigurationService} from '../config/configuration.service';
import {EnvironmentEnum} from 'src/app/enums/environment.enum';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe, NgIf, UpperCasePipe} from '@angular/common';
import {SelectLanguageComponent} from 'src/app/core/select-language/select-language.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [RouterLink, TranslateModule, UpperCasePipe, AsyncPipe, NgIf, SelectLanguageComponent],
})
export class HeaderComponent {
    public environmentEnum = EnvironmentEnum;
    public currentEnv = this.config.getCurrentEnv();

    public authorized$: Observable<boolean> = this.store
        .select(AppState.isAuthenticated)
        .pipe(startWith(false));

    constructor(
        private router: Router,
        private store: Store,
        private authService: AuthenticationService,
        private sidebar: SidebarService,
        private config: ConfigurationService,
    ) {}

    public login(): void {
        this.authService.login();
    }

    public onMenu(): void {
        this.sidebar.openMenu('menu');
    }

    public onSearch(): void {
        this.sidebar.openMenu('search');
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-failed',
    templateUrl: './failed.component.html',
    styleUrls: ['./failed.component.scss'],
    standalone: true,
})
export class FailedComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

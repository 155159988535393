import {Component, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core';
import {IDropdownPanel} from 'src/app/shared/components/custom-dropdown/dropdown-panel.interface';

@Component({
    selector: 'app-custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss'],
    standalone: true,
})
export class CustomDropdownComponent implements IDropdownPanel {
    @ViewChild(TemplateRef) public templateRef: TemplateRef<any>;
    @Output() public closed = new EventEmitter<void>();
}

<button aria-label="language menu"
        class="button"
        [appDropdownTriggerFor]="dropdown"
        (opened)="menuToggle($event)">
  <span [class.btn-opened]="menuOpened">{{ this.currentLang$ | async }}</span>
  <i class="far fa-chevron-down" [class.opened]="menuOpened"></i>
</button>
<app-custom-dropdown #dropdown>
  <div *ngFor="let lang of availableLang" (click)="selectLang(lang.key)" class="ds-dropdown-item"
       [class.selected]="(this.currentLang$ | async) === lang.key">
    {{ lang.value | translate }}
  </div>
</app-custom-dropdown>

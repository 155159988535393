import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FeatureKey} from './feature-key.enum';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {CFG_FEATURE_FLAG_PREFIX, DEFAULT_FEATURE_FLAG_VISIBILITY} from 'src/app/shared/services/feature-flag/feature-flag.helper';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {

    constructor(
        private config: ConfigurationService
    ) { }

    public isFeatureFlagEnabled(
        featureKey: FeatureKey,
        options: {fallbackValue: boolean} = {fallbackValue: DEFAULT_FEATURE_FLAG_VISIBILITY}
    ): Observable<boolean> {
        const featureFlagVisibility = this.config.get(`${CFG_FEATURE_FLAG_PREFIX}.${featureKey}`) ?? options.fallbackValue;
        return of(featureFlagVisibility);
    }

    public isShowProfileMedia(): Observable<boolean> {
        return this.isFeatureFlagEnabled(FeatureKey.SHOW_PROFILE_MEDIA);
    }
}

import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {IDropdownPanel} from 'src/app/shared/components/custom-dropdown/dropdown-panel.interface';

@Directive({
    selector: '[appDropdownTriggerFor]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '(click)': 'toggleDropdown()',
    },
    standalone: true,
})
export class DropdownTriggerForDirective implements OnInit, OnDestroy {
    private _openStatusSorce = new BehaviorSubject<boolean>(false);

    get isDropdownOpen(): boolean {
        return this._openStatusSorce.getValue();
    }

    set isDropdownOpen(status: boolean) {
        this._openStatusSorce.next(status);
    }

    private overlayRef: OverlayRef;
    private dropdownClosingActionsSub = Subscription.EMPTY;

    @Input('appDropdownTriggerFor') public dropdownPanel: IDropdownPanel;
    @Output() public opened: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _subscription: Subscription;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    public ngOnInit(): void {
        this._subscription = this._openStatusSorce.subscribe((status) => this.opened.emit(status));
    }

    public toggleDropdown(): void {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }

    public openDropdown(): void {
        this.isDropdownOpen = true;
        this.overlayRef = this.overlay.create({
            // width: 400,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 15,
                    },
                ]),
        });

        const templatePortal = new TemplatePortal(
            this.dropdownPanel.templateRef,
            this.viewContainerRef,
        );
        this.overlayRef.attach(templatePortal);

        this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() =>
            this.destroyDropdown(),
        );
    }

    private dropdownClosingActions(): Observable<MouseEvent | void> {
        const backdropClick$ = this.overlayRef.backdropClick();
        const detachment$ = this.overlayRef.detachments();
        const dropdownClosed = this.dropdownPanel.closed;

        return merge(backdropClick$, detachment$, dropdownClosed);
    }

    private destroyDropdown(): void {
        if (!this.overlayRef || !this.isDropdownOpen) {
            return;
        }

        this.dropdownClosingActionsSub.unsubscribe();
        this.isDropdownOpen = false;
        this.overlayRef.detach();
    }

    public ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        this._subscription?.unsubscribe();
        this._openStatusSorce.complete();
    }
}

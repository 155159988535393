import {ErrorHandler, NgModule} from '@angular/core';
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';



@NgModule({
    providers: [
        AppInsightsService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService,
        },
    ],
})
export class AppInsightsModule {
    constructor(private appInsightsService: AppInsightsService) {}
}

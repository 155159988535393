import {IMenuItem} from 'src/app/core/menu/menu-item.interface';
import {FeatureKey} from 'src/app/shared/services/feature-flag/feature-key.enum';

export const AUTH_MENU_ITEMS: IMenuItem[] = [
    {label: 'Home', link: ['/home']},
    {
        label: 'profile',
        children: [
            {label: 'personalData', link: ['/profile']},
            // {label: 'meansOfPayment', link: ['/']},
            {label: 'parties', link: ['/party']},
            {label: 'family', link: ['/party/family']},
            {label: 'cards', link: ['/cards']},
            {label: 'media', link: ['/profile/media'], featureFlag: FeatureKey.SHOW_PROFILE_MEDIA},
            {label: 'activities', link: ['/activities']},
            {label: 'sharedData', link: ['/shared-data']},
            // WILL BE ADDED LATER
            // {label: 'purposeOfData', link: ['/data-purpose']},
            // {label: 'legalDocs', link: ['/']},
            {label: 'partnerData', link: ['/profile/partner-data']},
            {label: 'trophies', link: ['/profile/trophies']},
        ],
    },
    {
        label: 'orders',
        children: [
            {label: 'allOrders', link: ['/orders']},
            {label: 'allTickets', link: ['/tickets']},
        ],
    },
    {label: 'servicesAndPartners', link: ['/services-and-partner']},
    {label: 'userAccount', link: ['/user-account']},
    {
        label: 'helpAndSupport',
        children: [
            {label: 'FAQ', link: ['/knowledge-base']},
            {label: 'Support', link: ['/support']},
            {label: 'Impressum', link: ['/impressum']},
        ],
    },
    {label: 'Logout', action: 'logout'},
];

export const NON_AUTH_MENU_ITEMS: IMenuItem[] = [
    {label: 'login', action: 'login'},
    {label: 'help', link: ['/help']},
];

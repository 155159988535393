<div class="footer-wrapper">
    <ul class="footer-list">
        <li class="footer-list__item">
            <i class="fal fa-chevron-circle-right"></i>
            <a routerLink="/knowledge-base">FAQ</a>
        </li>
        <li class="footer-list__item">
            <i class="fal fa-chevron-circle-right"></i>
            <a routerLink="/support">{{'customerSupport' | translate}}</a>
        </li>
        <li class="footer-list__item">
            <i class="fal fa-chevron-circle-right"></i>
            <a [href]="termsLink$ | async">{{'termsOfUse' | translate}}</a>
        </li>
    </ul>

    <p>© {{year}} {{'cooperative' | translate}} discover.swiss</p>
</div>

<div class="menu-wrapper" [class.search-back]="inSearch">
    <div class="menu-header">
        <div class="menu-header-btn" style="margin-right: auto" *ngIf="inChildren$ | async" (click)="toggleMenu()">
            <i class="fal fa-chevron-left"></i>
            <span>{{'back' | translate}}</span>
        </div>
        <app-menu-lang-selector *ngIf="(inChildren$ | async) === false" class="menu-header-btn desktop-view"></app-menu-lang-selector>
        <div class="menu-header-btn" style="margin-left: auto" (click)="close()">
            <span class="shift-up">{{'close' | translate}}</span>
            <i class="fal fa-times"></i>
        </div>
    </div>

    <ng-container [ngSwitch]="state">
        <nav class="menu-list" *ngSwitchCase="'menu'">
            <ul>
                <ng-container *ngFor="let item of currentMenuItems$ | async">
                    <ng-container *ngIf="(item | dsNavItemVisibility | async)?.hidden === false">
                        <li class="menu-list__item" *ngIf="item.link">
                            <a [routerLink]="item.link" queryParamsHandling="preserve">{{item.label | translate}}</a>
                        </li>
                        <li class="menu-list__item" *ngIf="item.children"
                            (click)="toggleMenu(item.children)">
                            <span>{{item.label | translate}}</span>
                            <i class="fal fa-chevron-right"></i>
                        </li>
                        <li class="menu-list__item" *ngIf="item.action" (click)="onActionClick(item.action)">
                            <span>{{item.label | translate}}</span>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </nav>
        <div class="menu-list" *ngSwitchCase="'search'">
            <div class="menu-list__search">
                <h1>{{'howCanHelp' | translate}}</h1>
                <input class="menu-list__search__control" type="text" [placeholder]="'searchGuestPortal' | translate"
                    [formControl]="searchControl">
                <button class="button menu-list__search__btn" type="submit">
                    <i class="far fa-search"></i>
                    <span>{{'search' | translate}}</span>
                </button>
            </div>
        </div>
        <app-data-description *ngSwitchCase="'desc'" [text]="descriptionText"></app-data-description>
    </ng-container>

    <div class="menu-controls"
        [class]="(inChildren$ | async) || inSearch ? 'justify-content-center' : 'justify-content-between'">
        <!-- TODO remove hide class when search implementetion will be redy -->
        <div class="menu-controls__item hide" *ngIf="(inChildren$ | async) === false && !inSearch" (click)="onSearch()">
            <i class="far fa-search"></i>
        </div>
        <div class="menu-controls__item" *ngIf="(inChildren$ | async) === false && !inSearch"
            (click)="menuItemClick(false)">
            <i class="fal fa-times"></i>
        </div>
        <div class="menu-controls__item" *ngIf="(inChildren$ | async) || inSearch"
            (click)="inSearch ? onSearch() : toggleMenu()">
            <i class="fal fa-arrow-left"></i>
        </div>
    </div>
</div>

import {Component} from '@angular/core';
import {AvailableLang} from '@discover-swiss/models';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {LANGS} from 'src/app/modules/profile/profile.service';
import {DropdownTriggerForDirective} from 'src/app/shared/components/custom-dropdown/dropdown-trogger-for.directive';
import {AsyncPipe, NgForOf} from '@angular/common';
import {CustomDropdownComponent} from 'src/app/shared/components/custom-dropdown/custom-dropdown.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-menu-lang-selector',
    templateUrl: './menu-lang-selector.component.html',
    styleUrls: ['./menu-lang-selector.component.scss'],
    standalone: true,
    imports: [
        DropdownTriggerForDirective,
        AsyncPipe,
        CustomDropdownComponent,
        NgForOf,
        TranslateModule,
    ],
})
export class MenuLangSelectorComponent {
    public menuOpened = false;

    public currentLang$ = this.langService.languageChange$;
    public availableLang = LANGS;

    constructor(private langService: LanguageService) {}

    public menuToggle(status: boolean): void {
        this.menuOpened = status;
    }

    public selectLang(lang: string): void {
        this.langService.use(lang as AvailableLang);
    }
}

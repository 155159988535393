<mat-sidenav-container>
    <mat-sidenav *ngIf="(isMobile$ | async) === false" [position]="'end'"
                 [mode]="'over'"
                 [opened]="menuOpen$ | async"
                 (closed)="menuItemClick(null)">
        <ng-container [ngTemplateOutlet]="applicationMenu"></ng-container>
    </mat-sidenav>
    <ng-container *ngIf="(isMobile$ | async) === true && menuOpen$ | async" [ngTemplateOutlet]="applicationMenu"></ng-container>
    <div class="app-wrapper">
        <div class="app-wrapper__content">
            <app-header></app-header>
            <div class="content-wrapper">
                <ng-container *ngIf="(isAppLoading$ | async) === false; else globalLoader">
                    <router-outlet></router-outlet>
                </ng-container>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</mat-sidenav-container>

<div class="navigation-btn" (click)="openMenu()" *ngIf="(isMobile$ | async) === true && (menuOpen$ | async) === false">
    <i class="fal fa-bars"></i>
</div>

<ng-template #globalLoader>
    <app-loader class="global-loader"></app-loader>
</ng-template>

<ng-template #applicationMenu>
    <app-menu [menuParams]="sidebar.menuData | async"></app-menu>
</ng-template>

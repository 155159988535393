import {Component, Input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {IMenuItem} from 'src/app/core/menu/menu-item.interface';
import {MenuService} from 'src/app/core/menu/menu.service';
import {AsyncPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MenuLangSelectorComponent} from 'src/app/core/menu/menu-lang-selector/menu-lang-selector.component';
import {DsNavItemVisibilityPipe} from 'src/app/shared/pipes/ds-nav-item-visibility.pipe';
import {RouterLink} from '@angular/router';
import {DataDescriptionComponent} from 'src/app/shared/components/data-description/data-description.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        TranslateModule,
        MenuLangSelectorComponent,
        NgSwitch,
        DsNavItemVisibilityPipe,
        RouterLink,
        NgSwitchCase,
        NgForOf,
        ReactiveFormsModule,
        DataDescriptionComponent,
    ],
})
export class MenuComponent {
    @Input()
    public set menuParams(value: {mode?: 'menu' | 'search' | 'desc'; text?: string}) {
        // TODO ask about this menu work strategy and uncomment in case if it does not fit requirements
        this.menuService.inChildren = false;
        this.menuService.toggleMenu();
        this.state = value.mode ?? 'menu';
        this.descriptionText = value.text ?? '';
    }

    public searchControl = new FormControl('');
    public inSearch: boolean = false;
    public inChildren$ = this.menuService.inChildren$;
    public state: 'menu' | 'search' | 'desc' = 'menu';
    public descriptionText: string = '';
    public currentMenuItems$ = this.menuService.currentMenuItems$;

    constructor(private menuService: MenuService) {}

    public toggleMenu(children?: IMenuItem[]): void {
        this.menuService.toggleMenu(children);
    }

    public menuItemClick(e?: string[] | string | boolean): void {
        this.menuService.menuItemClick(e);
    }

    public onSearch(): void {
        this.state = this.inSearch ? 'menu' : 'search';
        this.inSearch = !this.inSearch;
    }

    public logout(): void {
        this.menuService.logout();
    }

    public login(): void {
        this.menuService.login();
    }

    public close(): void {
        this.menuService.close();
    }

    public onActionClick(action: string): void {
        const actionMap = {
            login: () => this.login(),
            logout: () => this.logout(),
        };

        const exec = actionMap[action];
        this.menuService.close();
        if (exec) {
            exec();
        } else {
            throw `there is no implemenation for action '${action}'`;
        }
    }
}

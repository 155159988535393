import {Component, HostListener, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from './store/app/app.state';
import {combineLatest, fromEvent, Observable, Subject} from 'rxjs';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {distinctUntilChanged, filter, map, startWith, takeUntil} from 'rxjs/operators';
import {EventMessage, EventType, InteractionStatus, Logger, LogLevel} from '@azure/msal-browser';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {Authenticate} from 'src/app/store/app/app.actions';
import {SidebarService} from './shared/services/sidebar.service';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';
import {MenuService} from 'src/app/core/menu/menu.service';
import {Router, NavigationStart, NavigationEnd, NavigationCancel} from '@angular/router';
import {GetProfile} from 'src/app/store/profile/profile.actions';
import {EmailVerificationService} from 'src/app/shared/services/email-verification.service';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {eSeverityLevel, SeverityLevel} from '@microsoft/applicationinsights-web';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @Select(AppState.isAuthenticated) public auth$: Observable<boolean>;

    public isIframe = false;

    public menuOpen$ = this.menuService.menuOpen$;
    public isMobile$ = this.windowSizeService.isMobile$;

    private _isAppLoading1$ = this.customAuthService.msalInProgress$.pipe(
        map((msalInProgress) => msalInProgress !== InteractionStatus.None),
        distinctUntilChanged(),
    );

    private _isAppLoading2$ = this.router.events.pipe(
        filter(
            (e) =>
                e instanceof NavigationStart ||
                e instanceof NavigationEnd ||
                e instanceof NavigationCancel
        ),
        map((e) => e instanceof NavigationStart),
        distinctUntilChanged(),
        startWith(false)
    );

    public isAppLoading$ = combineLatest([
        this._isAppLoading1$,
        this._isAppLoading2$,
    ]).pipe(map(([isAppLoading1, isAppLoading2]) => isAppLoading1 || isAppLoading2));

    private _destroying$ = new Subject<void>();

    constructor(
        public sidebar: SidebarService,
        private router: Router,
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private store: Store,
        private windowSizeService: WindowSizeService,
        private menuService: MenuService,
        private emailVerificationService: EmailVerificationService,
        private customAuthService: AuthenticationService,
        private insight: AppInsightsService,
    ) {}

    public ngOnInit(): void {
        this.authService.instance.setLogger(
            new Logger({
                loggerCallback: (logLevel: LogLevel, message, piiEnabled) => {
                    
                    let severityLevel: eSeverityLevel;
                    // Map MSAL log levels to App Insight severity level
                    // Enum value order do not match so not possible to match by int value
                    switch (logLevel)
                    {
                        case 0: // Error = 0,
                            severityLevel = SeverityLevel.Error;
                            break;
                        case 1: // Warning = 1,
                            severityLevel = SeverityLevel.Warning;
                            break;
                        case 2: // Info = 2,
                            severityLevel = SeverityLevel.Information;
                            break;
                        case 3: // Verbose = 3,
                            severityLevel = SeverityLevel.Verbose;
                            break;
                        case 4: // Trace = 4
                            severityLevel = SeverityLevel.Verbose;
                            break;
                        default:
                            severityLevel = SeverityLevel.Information;
                            break;
                    }

                    this.insight.trackTrace({
                        message: 'MSAL: ' + message,
                        severityLevel: severityLevel,
                    });
                },
                piiLoggingEnabled: false,
                logLevel: LogLevel.Info
            }));


        // we always need to completely reload the full page
        // in case if it was restored from Back Forward Cache (B/F Cache).
        fromEvent(window, 'pageshow')
            .pipe(
                filter((event: PageTransitionEvent) => event.persisted),
                takeUntil(this._destroying$),
            ).subscribe({
                next: () => window.location.reload(),
            });

        this.isIframe = window !== window.parent && !window.opener;
        this.windowSizeService.setWidth(window.innerWidth);
        this.translateService.addLangs(this.languageService.availableLang);
        this.languageService.languageChange$.subscribe((lang) => this.translateService.use(lang));
        this.translateService.use(this.languageService.currentLang);
        if (this.authService.instance.getAllAccounts().length > 0) {
            this.store.dispatch(new Authenticate());
        }

        this.msalBroadcastService.inProgress$
            .pipe(
                // Filtering for all interactions to be completed
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                if (this.authService.instance.getAllAccounts().length > 0) {
                    this.store.dispatch(new GetProfile());
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                this.store.dispatch(new Authenticate());
                if (result.eventType === EventType.LOGIN_SUCCESS) {
                    if (this.emailVerificationService.isEmailVerificationInProgress) {
                        const emailVerificationKey =
                            this.emailVerificationService.getEmailVerificationKey();
                        const emailVerificationLanguage =
                            this.emailVerificationService.getEmailVerificationLanguage();

                        this.emailVerificationService.removeEmailVerificationKey();
                        this.emailVerificationService.removeEmailVerificationLanguage();

                        this.router.navigate(['/profile/verify'], {
                            queryParams: {
                                emailVerificationKey: emailVerificationKey,
                                lang: emailVerificationLanguage,
                            },
                        });
                    } else {
                        this.router.navigate(['/home'], {queryParamsHandling: 'merge'});
                    }
                }
                return result;
            });
    }

    public menuItemClick(e: any): void {
        this.menuService.menuItemClick(e);
    }

    public openMenu(): void {
        this.menuService.open();
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.windowSizeService.setWidth(window.innerWidth);
    }
}

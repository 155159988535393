import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExtendedMsalGuard} from './core/auth/extended-msal.guard';
import {FailedComponent} from './core/failed/failed.component';
import {GuestGuard} from './shared/guards/guest.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/landing/routing'),
        canActivate: [GuestGuard],
    },
    {
        path: 'account-deletion',
        loadChildren: () => import('./modules/account-deletion/routing'),
        canActivate: [GuestGuard],
    },
    {
        path: 'home',
        loadChildren: () => import('./modules/home/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'cards',
        loadChildren: () => import('./modules/cards/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'tickets',
        loadChildren: () => import('./modules/tickets/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'orders',
        loadChildren: () => import('./modules/orders/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/profile/routing'),
    },
    {
        path: 'activities',
        loadChildren: () => import('./modules/activities/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'shared-data',
        loadChildren: () => import('./modules/shared-data/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'party',
        loadChildren: () => import('./modules/party/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'user-account',
        loadChildren: () => import('src/app/modules/user-account/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    // {
    //     path: 'data-purpose',
    //     loadChildren: () => import('./modules/data-purpose/data-purpose.module').then((m) => m.DataPurposeModule),
    //     canActivate: [MsalGuard]
    // },
    {
        path: 'services-and-partner',
        loadChildren: () => import('./modules/services-and-partner/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'knowledge-base',
        loadChildren: () => import('./modules/knowledge-base/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'support',
        loadChildren: () => import('./modules/support/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        path: 'impressum',
        loadChildren: () => import('./modules/impressum/routing'),
        canActivate: [ExtendedMsalGuard],
    },
    {
        // Needed for hash routing
        path: 'error',
        redirectTo: '/',
    },
    {
        // Needed for hash routing
        path: 'state',
        redirectTo: '/home',
    },
    {
        // Needed for hash routing
        path: 'code',
        redirectTo: '/home',
    },
    {
        path: 'login-failed',
        component: FailedComponent,
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            // enableTracing: true,
            // scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {IMenuItem} from 'src/app/core/menu/menu-item.interface';
import {AUTH_MENU_ITEMS, NON_AUTH_MENU_ITEMS} from 'src/app/core/menu/menu-items';
import {SidebarService} from 'src/app/shared/services/sidebar.service';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService implements OnDestroy {
    private _inChildrenSource$ = new BehaviorSubject<boolean>(false);
    public inChildren$ = this._inChildrenSource$.asObservable();
    private _currentMenuItemsSource$ = new BehaviorSubject<IMenuItem[]>([]);
    public currentMenuItems$ = this._currentMenuItemsSource$.asObservable();
    private _menuOpenSource$ = new BehaviorSubject<boolean>(false);
    public menuOpen$ = this._menuOpenSource$.asObservable();

    private _destroy$ = new Subject<void>();

    get menuItems(): IMenuItem[] {
        return this.auth.loggedIn ? AUTH_MENU_ITEMS : NON_AUTH_MENU_ITEMS;
    }

    set inChildren(value: boolean) {
        this._inChildrenSource$.next(value);
    }

    constructor(
        private auth: AuthenticationService,
        private windowSizeService: WindowSizeService,
        private sidebar: SidebarService,
        private router: Router,
    ) {
        this.toggleMenu();
        // better to unsubscribe due to potential issues if we decide use HMR
        this.sidebar.opened.pipe(takeUntil(this._destroy$)).subscribe((res) => this._menuOpenSource$.next(res));
        merge(
            windowSizeService.viewSwitched$.pipe(filter(() => auth.loggedIn)),
            router.events.pipe(filter((event) => event instanceof NavigationEnd)),
        ).pipe(
            takeUntil(this._destroy$)
        ).subscribe(() => this.close());
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public open(): void {
        this._menuOpenSource$.next(true);
    }

    public close(): void {
        this._menuOpenSource$.next(false);
    }

    public toggleMenu(children?: IMenuItem[]): void {
        if (!children) {
            this._inChildrenSource$.next(false);
            this._currentMenuItemsSource$.next(this.menuItems);
        } else {
            this._inChildrenSource$.next(true);
            this._currentMenuItemsSource$.next(children);
        }
    }

    public menuItemClick(e?: string[] | string | boolean): void {
        this.close();
        this.sidebar.opened.next(false);
        this.sidebar.menuData.next({});
        if (Array.isArray(e)) {
            this.router.navigate(e, {queryParamsHandling: 'preserve'});
        }
    }

    public login(): void {
        this.auth.login();
    }

    public logout(): void {
        this.auth.logout();
    }
}

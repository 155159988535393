import {Component} from '@angular/core';
import {TermsService} from 'src/app/shared/services/terms.service';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [TranslateModule, AsyncPipe, RouterLink],
})
export class FooterComponent {
    public termsLink$ = this.termsService.termsLink$;
    public year: number = new Date().getFullYear();

    constructor(private termsService: TermsService) {}
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Source: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1403
import {NgModule, ModuleWithProviders} from '@angular/core';
import {IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel} from '@azure/msal-browser';
import {
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration,
    MSAL_INSTANCE,
} from '@azure/msal-angular';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ExtendedMsalService} from 'src/app/core/auth/emsal.service';
import {ExtendedMsalGuard} from 'src/app/core/auth/extended-msal.guard';

// Remove this line to use Angular Universal
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel, message): void {
    // console.log("MSAL Angular: ", logLevel ,message);
}

export function MSALInstanceFactory(config: ConfigurationService): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: config.get('clientID'),
            authority: `${config.get('authority')}/${config.get('signUpSignInPolicy')}`,
            redirectUri: '/',
            postLogoutRedirectUri: '/',
            navigateToLoginRequestUrl: true,
            knownAuthorities: config.get('knownAuthorities')
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: true,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(config: ConfigurationService): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>(config.get('protectedResourceMap'));
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(config: ConfigurationService): MsalGuardConfiguration {
    const scopes = config.get('scopes');

    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: scopes
        },
        loginFailedRoute: '/login-failed'
    };
}

@NgModule({
    providers: [],
    imports: [MsalModule],
})
export class MsalApplicationModule {
    public static forRoot(): ModuleWithProviders<MsalApplicationModule> {
        return {
            ngModule: MsalApplicationModule,
            providers: [
                ConfigurationService,
                {
                    provide: MSAL_INSTANCE,
                    useFactory: MSALInstanceFactory,
                    deps: [ConfigurationService],
                },
                {
                    provide: MSAL_GUARD_CONFIG,
                    useFactory: MSALGuardConfigFactory,
                    deps: [ConfigurationService],
                },
                {
                    provide: MSAL_INTERCEPTOR_CONFIG,
                    useFactory: MSALInterceptorConfigFactory,
                    deps: [ConfigurationService],
                },
                {
                    provide: MsalService,
                    useClass: ExtendedMsalService,
                },
                ExtendedMsalGuard,
                MsalBroadcastService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true,
                },
            ],
        };
    }
}

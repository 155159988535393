import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class GuestGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Redirect to home if user is authenticated
        if (!route.routeConfig.path && this.authService.loggedIn) {
            const queryParams = route.queryParams;
            this.router.navigate(['/home'], {queryParams});
        }
        return true;
    }
}

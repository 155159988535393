<button aria-label="language menu"
        matTooltip="{{'switchLanguage' | translate}}"
        class="button"
        [matMenuTriggerFor]="languageMenu"
        (menuOpened)="menuOpened = true"
        (menuClosed)="menuOpened = false">
  <span>{{ this.currentLang$ | async }}</span>
  <i class="far fa-chevron-down" [ngClass]="{'opened': menuOpened}"></i>
</button>
<mat-menu #languageMenu="matMenu" xPosition="before">
  <div *ngFor="let lang of langService.availableLang">
    <button class="button" mat-menu-item (click)="langService.use($any(lang))">
      <span style="text-transform: uppercase">{{ lang }}</span>
    </button>
  </div>
</mat-menu>

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationService} from '../core/config/configuration.service';


export class RequestPartnerInterceptor implements HttpInterceptor {

    constructor(
        private config: ConfigurationService,
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const requestpartner = this.config.get('requestpartner');
        // if (requestpartner != null) {
        //
        // }
        req = req.clone({
            headers: req.headers.append('request-partner', 'ds')
        });
        return next.handle(req);
    }
}

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
}

fetch('./assets/config.json')
    .then((res) => res.json())
    .then((res) => {
        platformBrowserDynamic([
            {
                provide: 'appConfigToken',
                useValue: res,
            },
        ])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    });

import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {LanguageService} from "src/app/core/i18n/language.service";

@Injectable({providedIn: 'root'})
export class TermsService {
    private readonly termsLinks = {
        de: 'https://discover.swiss/agb-gast',
        en: 'https://discover.swiss/en/gtc-guest',
        it: 'https://discover.swiss/it/cg-ospite',
        fr: 'https://discover.swiss/fr/cg-client'
    }
    public termsLink$ = this.languageService.languageChange$.pipe(
        map(lang => this.termsLinks[lang])
    );

    constructor(private languageService: LanguageService) {}
}

import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of, switchMap} from 'rxjs';
import {IMenuItem} from 'src/app/core/menu/menu-item.interface';
import {FeatureFlagService} from 'src/app/shared/services/feature-flag/feature-flag.service';

@Pipe({
    name: 'dsNavItemVisibility',
    standalone: true,
})
export class DsNavItemVisibilityPipe implements PipeTransform {

    constructor(
        private featureFlag: FeatureFlagService,
    ) { }

    public transform<T extends IMenuItem>(item: T): Observable<T> {
        return of(item).pipe(
            switchMap((i) => {
                if (!i.featureFlag) return of(false);
                return this.featureFlag.isFeatureFlagEnabled(i.featureFlag).pipe(
                    map((isEnabled) => !isEnabled),
                );
            }),
            map((isHidden) => ({...item, hidden: isHidden})),
        );
    }
}
